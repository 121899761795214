<template>
  <moe-page title="测试白名单">
    <moe-inquire @search="testWhiteListSearch">
      <el-form-item label="用户ID">
        <el-input :value="testWhiteListParams.userId" @input="(value) => testWhiteListParams.userId = value.replace(/^\.+|[^\d]/g, '')" placeholder="输入用户ID" maxlength="50" clearable />
      </el-form-item>
      <el-form-item label="用户名称">
        <el-input v-model.trim="testWhiteListParams.userName" placeholder="输入用户名称" maxlength="50" clearable />
      </el-form-item>
      <el-form-item label="用户手机号">
        <el-input v-model.trim="testWhiteListParams.userPhone" placeholder="输入用户手机号" maxlength="50" clearable />
      </el-form-item>
    </moe-inquire>

    <moe-table ref="testWhiteListTable" url="/factoryTest/user/list" :params="testWhiteListParams" :number-show="false">
      <!-- 操作按钮 -->
      <template slot="tool">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showDialog = true;">新增白名单</el-button>
      </template>

      <el-table-column label="用户ID" prop="userId" />
      <el-table-column label="用户名称" prop="userName" :show-overflow-tooltip="true" />
      <el-table-column label="用户手机号" prop="userPhone" />
      <el-table-column label="用户头像">
        <template slot-scope="{ row }">
          <moe-image v-if="row.userIcon" :src="row.userIcon" width="50px" height="50px"></moe-image>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" min-width="150">
        <template slot-scope="{ row }">
          {{ $moe_time.getTimeStamp(row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column label="创建人" prop="createUser" :show-overflow-tooltip="true" />
      <el-table-column label="操作">
        <template slot-scope="{ row }">
          <el-button type="danger" size="mini" icon="el-icon-delete" @click="delFactoryTest(row)">移除名单</el-button>
        </template>
      </el-table-column>
    </moe-table>

    <moe-dialog :show="showDialog" title="新增白名单" width="50%" @close="handleDialogClose()">
      <moe-inquire @search="addFactoryTestSearch">
        <el-form-item label="用户ID">
          <el-input :value="addFactoryTestParams.id" @input="(value) => addFactoryTestParams.id = value.replace(/^\.+|[^\d]/g, '')" placeholder="输入用户ID" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="用户名称">
          <el-input v-model.trim="addFactoryTestParams.name" placeholder="输入用户名称" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="用户手机号">
          <el-input v-model.trim="addFactoryTestParams.phone" placeholder="输入用户手机号" maxlength="50" clearable />
        </el-form-item>
      </moe-inquire>

      <moe-describe-list title="用户信息" :col="2" v-if="userInfo.id">
        <moe-describe-item label="用户ID"> {{ userInfo.id }} </moe-describe-item>
        <moe-describe-item label="用户名称"> {{ userInfo.name }} </moe-describe-item>
        <moe-describe-item label="用户手机号"> {{ userInfo.phone }} </moe-describe-item>
        <moe-describe-item label="用户头像">
          <moe-image v-if="userInfo.icon" :src="userInfo.icon" width="100px" height="100px"></moe-image>
          <div v-else>-</div>
        </moe-describe-item>
      </moe-describe-list>

      <template slot="footer">
        <el-button @click="handleDialogClose()">取消</el-button>
        <el-button type="primary" @click="addFactoryTest()">添加</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'HardwareTestTestWhiteListList',
  data() {
    return {
      testWhiteListParams: {
        pageNum: 1,
        pageSize: 10,
        userId: '',
        userName: '',
        userPhone: '',
      },
      showDialog: false,
      addFactoryTestParams: {
        id: '',
        name: '',
        phone: '',
      },
      userInfo: {
        id: '',
        name: '',
        phone: '',
        icon: '',
      },
    }
  },
  methods: {
    testWhiteListSearch(isSearch) {
      if (!isSearch) {
        this.testWhiteListParams = {
          pageNum: 1,
          pageSize: 10,
        };
      }

      this.$refs['testWhiteListTable'].searchData();
    },
    /** 用户精准搜索 */
    strictSearch() {
      let params = {}
      for (let key in this.addFactoryTestParams) {
        if (this.addFactoryTestParams[key]) {
          params[key] = this.addFactoryTestParams[key];
        }
      }
      this.$moe_api.USER_API.strictSearch(params).then((data) => {
        if (data.code === 200) {
          if (data.result) {
            this.userInfo = data.result;
          } else {
            this.userInfo = {
              id: '',
              name: '',
              phone: '',
              icon: '',
            };
            this.$moe_msg.warning('未找到该用户');
          }
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    handleDialogClose() {
      this.showDialog = false;
      this.addFactoryTestSearch(false);
    },
    /** 用户 搜索和重置 */
    addFactoryTestSearch(isSearch) {
      if (!isSearch) {
        this.addFactoryTestParams = {
          id: '',
          name: '',
          phone: '',
        };
        this.userInfo = {
          id: '',
          name: '',
          phone: '',
          icon: '',
        };
      } else {
        if (!this.addFactoryTestParams.id && !this.addFactoryTestParams.name && !this.addFactoryTestParams.phone) {
          return this.$moe_msg.warning('请输入内容')
        }
        this.strictSearch();
      }
    },
    /** 新增厂测白名单 */
    addFactoryTest() {
      if (!this.userInfo.id) {
        return this.$moe_msg.warning('请先搜索用户')
      }
      this.$moe_api.HARDWARETEST_API.addFactoryTest({ userId: this.userInfo.id }).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('新增成功');
          this.testWhiteListSearch(false);
          this.handleDialogClose();
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 删除厂测白名单 */
    delFactoryTest({ userName, id }) {
      this.$moe_layer.confirm(`您确定要移除“${userName}”当前选项吗？`, () => {
        this.$moe_api.HARDWARETEST_API.delFactoryTest({ id }).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('操作成功');
            this.testWhiteListSearch(false);
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>